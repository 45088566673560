<template>
  <div style="width:100%">
    <List v-if="editType == 'list'"  @changeShowType="changeShowType"></List>
    <Child v-if="editType == 'child'" @changeShowType="changeShowType" :param="param"></Child>
  </div>
</template>

<script>
import List from './list'
import Child from './child'

export default {
  name: 'financeSemester',
  components: { List,Child },
  data() {
    return {
      editType:'list',
      param:{}
    }
    
  },
  created() {
  },
  methods: {
    changeShowType(type,param){
      this.param = param;
      this.editType = type;
    }
  }
}
</script>

<style>
</style>