<template>
  <div style="width:100%">
    <div class="search-box">
      <el-form inline size="small">
        <el-form-item label=" ">
          <el-form-item label="年份：">
          <el-date-picker v-model="form.year" format="yyyy" value-format="yyyy" type="year" placeholder="请选择年份" style="width:100px"> </el-date-picker>
        </el-form-item>
        <el-form-item label="学期">
          <el-select v-model="form.semester" placeholder="请选择" style="width:100px">
              <el-option label="春季学期" value="0"></el-option>
              <el-option label="秋季学期" value="1"></el-option>
            </el-select>
        </el-form-item>
         
      </el-form-item>

        <el-form-item label="地区" >
            <ChooseArea ref="chooseArea" v-model="areaValue"  @changeArea="changeArea" :checkStrictly="true" :selectedLevel="4" style="width:250px" ></ChooseArea>
          </el-form-item>
          <el-form-item label="学校" >
            <el-select v-model="schoolId" placeholder="请选择" style="width:200px" @change="schoolChange" :disabled="(form.proId || form.cityId || form.regId || form.streetId)?false:true">
              <el-option label="全部" value=""></el-option>
              <el-option v-for="schoolInfo in schoolList" :key="schoolInfo.id" :label="schoolInfo.name" :value="schoolInfo.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label=" ">
            <el-button type="primary" icon="el-icon-search" @click="handleCurrentChange(1)">查询</el-button>
          </el-form-item>

      </el-form>
    </div>
    <el-table :data="list" show-summary
    :tree-props="{children: 'childrenList', hasChildren: 'hasChildren'}"   row-key="id" stripe
     style="max-width: 100%;margin-top:15px;box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);">
      <el-table-column type="index" align="center" label="序号" width="50"></el-table-column>
      <el-table-column label="学校" align="center" show-overflow-tooltip prop="schoolName"></el-table-column>
      <el-table-column  align="center" show-overflow-tooltip prop="totalPersonNum">
        <template slot="header">
          征订人数
           <el-tooltip class="item" effect="dark" content="全部人数，包含退款" placement="bottom">
            <i class="el-icon-warning-outline"></i>
          </el-tooltip>
        </template>
      </el-table-column> 
      <el-table-column label="数量" align="center" show-overflow-tooltip >
        <el-table-column label="单计量" align="center" show-overflow-tooltip prop="singleMeasurementNum"></el-table-column> 
        <el-table-column label="多计量" align="center" show-overflow-tooltip prop="multiMeasurementNum"></el-table-column> 
      </el-table-column> 
      <el-table-column label="退款人数（人）" align="center" show-overflow-tooltip >
        <el-table-column label="全额" align="center" show-overflow-tooltip prop="allRefundPersonNum"></el-table-column> 
        <el-table-column label="部分" align="center" show-overflow-tooltip prop="partialRefundPersonNum"></el-table-column> 
      </el-table-column> 
      <el-table-column label="退款金额（元）" align="center" show-overflow-tooltip >
        <el-table-column label="全额" align="center" show-overflow-tooltip prop="allRefundMoney"></el-table-column> 
        <el-table-column label="部分" align="center" show-overflow-tooltip prop="partialRefundMoney"></el-table-column> 
        <el-table-column  align="center" show-overflow-tooltip prop="rateAllRefundMoney">
          <template slot="header">
          支出
           <el-tooltip class="item" effect="dark" content="公司承担的退款金额，扣除支付手续费" placement="bottom">
            <i class="el-icon-warning-outline"></i>
          </el-tooltip>
        </template>
        </el-table-column> 
      </el-table-column> 
      <el-table-column  align="center" show-overflow-tooltip prop="totalMoney">
        <template slot="header">
          收款金额（元）
           <el-tooltip class="item" effect="dark" content="订单金额，包含已退款的金额" placement="bottom">
            <i class="el-icon-warning-outline"></i>
          </el-tooltip>
        </template>
      </el-table-column> 
      <el-table-column  align="center" show-overflow-tooltip prop="rateActualMoney">
        <template slot="header">
          入账金额（元）
           <el-tooltip class="item" effect="dark" content="订单金额扣除支付手续费" placement="bottom">
            <i class="el-icon-warning-outline"></i>
          </el-tooltip>
        </template>
      </el-table-column> 
     
      <el-table-column align="center" label="操作" width="100">
        <template slot-scope="scope">
          <el-button type="text"  @click="editDetail(scope.row)">查看</el-button>         
        </template>
      </el-table-column>
    </el-table>
    <el-pagination layout="total, prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="form.pageSize" :current-page="form.pageNum" :total="total"></el-pagination>
 
      
  </div>
</template>

<script>
import ChooseArea from '@/components/ChooseArea/index.vue'
import { getPageListOfSemester,getAllSchoolList,getTotalInforOfSemester } from '@/api/index.js'
export default {
  name: 'financeSemester',
  components: {ChooseArea},
  data() { 
    return {
      showType: 0,
      editType: '',
      isAddDialog:false,
      form: {
        pageNum: 1,          //页码
        pageSize: 10,          //每页条数
        proId:'',
        cityId:'',
        regId:'',
        streetId:'',
        schoolName:'',    
        year:'', 
        semester:''
      },
      list: [],
      total: 0,
      areaValue:['','','',''],
      schoolId:'',    
      areaValue:['','','',''], 
      schoolList:[],
      totalData:{}
    }
    
  },
  created() {
    let nowDate = new Date();
    this.form.year = nowDate.getFullYear()+'';
    let currMonth = nowDate.getMonth();
    if(currMonth+1 < 7){
      this.form.semester = '0';
    }else{
      this.form.semester = '1';
    }
    this.getList();
  },
  methods: {
    editDetail(schoolInfo) {
      let param = {year:this.form.year,semester:this.form.semester,schoolName:schoolInfo.schoolName}
      this.$emit('changeShowType', 'child',param)
    },
    changeArea(selectedData){
      this.form.proId = selectedData.proId;
      this.form.cityId=selectedData.cityId;
      this.form.regId=selectedData.regId;
      this.form.streetId=selectedData.streetId;
      if(this.form.proId || this.form.cityId || this.form.regId || this.form.streetId){
        this.getSchoolList();
      }else{
        this.schoolList=[];
        this.schoolId = ''
        this.form.schoolName = '';
      }
    },
    getSchoolList(){
      let param = {
          isPage:0,
          proId: this.form.proId,
          cityId: this.form.cityId,
          regId: this.form.regId,
          streetId: this.form.streetId
        };
      getAllSchoolList(param).then(res => {
        if (res.code != 200) return
        this.schoolList = res.data
      })   

    },
    schoolChange(schoolId){
      this.form.schoolName = '';
      if(!schoolId){
        return;
      }

      let obj = this.schoolList.find((item)=>{
        return item.id == schoolId;
      }) 
      this.form.schoolName = obj.name 
    },
    getList() {
      this.getTotalInforOfSemester();
      getPageListOfSemester(this.form).then(res => {
        if (res.code != 200) return
        this.list = res.data.records
        this.total = res.data.total
      })
    },
    getSummaries(params){
      let sums = ['合计','',this.totalData.totalPersonNum
      ,this.totalData.singleMeasurementNum
      ,this.totalData.multiMeasurementNum
      ,this.totalData.allRefundPersonNum
      ,this.totalData.partialRefundPersonNum
      ,this.totalData.allRefundMoney
      ,this.totalData.partialRefundMoney
      ,this.totalData.rateAllRefundMoney
      ,this.totalData.totalMoney
      ,this.totalData.rateActualMoney
      ];
      return sums;
    },
    getTotalInforOfSemester() {
      getTotalInforOfSemester(this.form).then(res => {
        if (res.code != 200) return
        this.totalData = res.data
      })
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.handleCurrentChange(1)
    },
    handleCurrentChange(val) {
      this.form.pageNum = val
      this.getList()
    },
  }
}
</script>

<style>
.fast-date{
  font-size:14px;
  margin-left:10px;
  cursor: pointer;
}
.fast-date-selected{
  color:blue;
  text-decoration: underline;
  font-size:16px;
}
</style>